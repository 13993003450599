import React, { useEffect} from 'react';
import { Link } from 'react-router-dom';

export default function Home() {

    useEffect(() => {
        let intro = document.getElementById('intro');
        intro.classList.add("translate-x-20");
        intro.classList.remove("translate-x-20");
        intro.classList.remove("opacity-0");
        intro.classList.add("opacity-100");
    }, []) 

    // componentWillMount(){
    //     console.log("test");
    // }

    return (<>
        <div className='h-screen flex px-8 lg:px-0'>
            <iframe src={`/animation/index.html`} frameBorder="0" height="100%" width="100%" className='absolute overflow-hidden top-2 md:top-1 left-0 px-4 py-24 md:px-24' scrolling="no" />
            <h1 className="text-white my-auto mx-auto md:w-1/2 2xl:w-1/3 transition-all delay-700 duration-[1000ms] ease-out translate-x-20 opacity-0 z-50" id="intro">
            <span className='text-accent font-black'>[</span>Creating story driven experiences centered 
            around art, community, and creative technology.
            Leading the way with innovation, aesthetic and application of emerging tech in Web3<span className='text-accent font-black'>]</span>
            </h1>
        </div>
    </>);
}

// function mySketch() {

//     let gridSize = 30;
//     let nSides = 5 // number of "corners" in each shape
//     let particleSizeMin = 3;
//     let particleSizeMax = 15;
//     let lifeMin = 50;
//     let lifeMax = 100;
//     let spawnOff = 20;
//     let visibility = 20;
//     let bloxArray = []
//     let gravityDist = 130;
//     let border = 100;

//     let timer = 100;

//     let rainbow;

//     const setup = (p5, canvasParentRef) => {
//     createCanvas(windowWidth, windowHeight);
    
//     rectMode(CENTER);
//     colorMode(HSB);
//     angleMode(DEGREES)
    
//     rainbow = random(360)

    
//     // build grid
//     for(let x = border; x < width-border; x+=gridSize) {
//         for(let y = border; y < height-border; y+=gridSize) {
//         bloxArray.push(new Blox(x + (gridSize/2), y + (gridSize/2)))
//         }
//     }
    
//     noStroke()
//     }

//     function draw() {
//     // background(0);
//     clear()
    
//     // draw blox
//     for(let i = 0; i < bloxArray.length; i++) {
//         bloxArray[i].update()
//     }
    
//     // change colour over time
//     rainbow = (rainbow + 1) % 360
    
//     // gravityDist = map(abs(mouseX - pmouseX), 3, 50, 30, 230) 
//     if(abs(mouseX - pmouseX) < 5) {
//         if(timer > 0) {
//         timer--
//         } else {
//         gravityDist-=0.5;
//         }
//     } else {
//         timer = 100;
//         gravityDist = 130;
//     }
//     }

//     function mousePressed() {
    
//     }

//     class Blox {
//     constructor(posX, posY) {
//         this.loc = createVector(width/2 + random(-spawnOff, spawnOff), height/2 + random(-spawnOff, spawnOff));
//         this.destination = createVector(posX, posY); // where it should finally go
//         this.acceleration = createVector(1,1);
//         this.col = color(30);
//         this.size = particleSizeMin;
//         this.shape = organicShape(this.size);
//         this.active = false;
//         this.angle = random(360)
//         this.lifeSpan = random(lifeMin, lifeMax);
//         this.activeRange = 0;
//     }
    
//     update() {
//         this.loc.x = lerp(this.loc.x, this.destination.x, 0.1);
//         this.loc.y = lerp(this.loc.y, this.destination.y, 0.1);
//         this.drawBlox()
//     }
    
//     drawBlox() {
//         let rectSize;
//         let fadePoint = 35;
        
//         if(this.active == true) {

//         if(this.lifeSpan < fadePoint) {
//             // if it is close to death, fade down the colour and normalise the size
//             let fade = map(this.lifeSpan, fadePoint-1, 0, 49, 0)
//             fill(rainbow, fade, 100)
            
//             rectSize = map(sin(this.angle), -1, 1, particleSizeMin, particleSizeMax);
//             let rate = map(this.lifeSpan, fadePoint-1, 0, 0.1, 1)
//             rectSize = lerp(rectSize, this.size, rate)
//         } else {
//             fill(rainbow, 50, 100)
//             rectSize = map(sin(this.angle), -1, 1, particleSizeMin, particleSizeMax);
            
//         }
        
//         // how long to stay active/colourful
//         if(this.lifeSpan <= 0) {
//             this.active = false;
//             this.lifeSpan = random(lifeMin,lifeMax);
//         } else {
//             this.lifeSpan--;
//         }
//         } else {
//         fill(this.col)
//         rectSize = map(sin(this.angle), -1, 1, particleSizeMin, particleSizeMin/2.5);
//         }
        
//         this.angle+=2
        
//         let mouseVec = createVector(mouseX, mouseY);
        
//         if(this.loc.dist(mouseVec) < visibility && abs(mouseX - pmouseX) > 1 && !this.active) {
//         this.active = true;
//         } else {
//         if (this.loc.dist(mouseVec) < gravityDist) {
//             let range = map(this.loc.dist(mouseVec), gravityDist, 0, 0, 0.1);
//             let rangeX = map(this.loc.x-mouseX, 200, 0, 0, 0.1);
//             let rangeY = map(this.loc.y-mouseY, 200, 0, 0, 0.1);
//             this.loc.x = constrain(lerp(this.loc.x, mouseX, rangeX), this.loc.x - 5, this.loc.x + 5) + sin(this.angle)
//             this.loc.y = constrain(lerp(this.loc.y, mouseY, rangeY), this.loc.y - 5, this.loc.y + 5) + sin(this.angle)
//             if(mouseIsPressed) {
//             this.lifespan = random(1, 30)
//             this.activeRange = 25;
//             this.active = true;
//             }
//             if(this.activeRange > 0) {
//             this.activeRange--
//             let amt = map(this.activeRange, 25, 0, 0, 1)
//             let from = color(this.col)
//             let to = color(rainbow, 50, 100)
//             let col3 = lerpColor(from, to, amt);
//             fill(col3)
//             }
//         }
//         rect(this.loc.x, this.loc.y, rectSize, rectSize, 4)
//         // this.drawShape(rectSize)
//         }
//     }
    
//     drawShape(size) {
//         let finalSize = map(size, 3, 20, 0.6, 2.2)
//         push()
//         translate(this.loc.x, this.loc.y)
//         scale(finalSize)
//         beginShape()
//         for(let i = 0; i < this.shape.length; i++) {
//             curveVertex(this.shape[i].x, this.shape[i].y);
//         }
//         endShape(CLOSE)
//         pop()
//     }
    
//     // UNUSED
//     checkNeighbours() {
//         for(let other = 0; other < bloxArray.length; other++) {
//         let d = dist(this.loc.x, this.loc.y, bloxArray[other].loc.x, bloxArray[other].loc.y);
//         if(other != this && d < gridSize + 3 && this.active && bloxArray[other].active) {
//             stroke(rainbow, 90, 100);
//             line(this.loc.x, this.loc.y, bloxArray[other].loc.x, bloxArray[other].loc.y)
//         } else {
//             noStroke();
//         }
//         }
//     }
    
//     }

//     function organicShape(siz) {
//     let temp_pos = [];
//     for(let p = 0; p < nSides; p++) {
//         let strictAgl = map(p, 0, nSides, 0, 360)
//         let agl = random(strictAgl-20, strictAgl+20)
//         let vec = createVector(sin(agl) * random(siz-2, siz+2), cos(agl) * random(siz-2, siz+2))
//         temp_pos.push(vec)
//     }
//     return temp_pos;
//     }

//     function windowResized() {
//     resizeCanvas(windowWidth, windowHeight);
//     // nuke array and start again
//     bloxArray = [];

//     for(let x = border; x < width-border; x+=gridSize) {
//         for(let y = border; y < height-border; y+=gridSize) {
//         bloxArray.push(new Blox(x + (gridSize/2), y + (gridSize/2)))
//         }
//     }
//     }

//     return(<Sketch setup={setup} draw={draw} windowResized={windowResized} />)

// }