import { Link } from 'react-router-dom';
import ICLogoW from './img/immutable-canvas-logo-w.svg';

export default function Navigation({ children }) {

    return (<>
        <div className='bg-black w-screen'>
            <nav className='pt-8 absolute'>
                <Link to="/">
                    <img src={ICLogoW} alt="Immutable Canvas" className='w-32 mx-8 z-40' />
                </Link>
            </nav>
            <main role="main" className='flex-grow'>
                { children }
            </main>
            <footer className='mx-8 absolute pb-8 bottom-0'>
                <span className='text-white select-none'>
                    Contact us<br />
                </span>
                    <div className='group relative overflow-hidden'>
                        <div className='bg-accent w-full h-0.5 absolute -bottom-0 transition-all -translate-x-[220px] group-hover:translate-x-0'></div>
                        <a href="mailto:hello@immutablecanvas.com" alt="contact us" className='relative'>hello@immutablecanvas.com</a>
                    </div>
            </footer>
        </div>
    </>);
}