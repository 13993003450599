import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ReactGA from 'react-ga';

import './App.css';
import Navigation from './nav.js';
import HomePage from './pages/home.js';

ReactGA.initialize('UA-217208835-2');
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  return (
    <Router>
      <Navigation>

      <Switch>
       <HomePage />
      </Switch>

      </Navigation>
    </Router>
  );
}

export default App;
